/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { css } from 'react-emotion';
import { Helmet } from 'react-helmet';
import { Box, PaperBox, Flex } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';
import compass from '../assets/images/compass-button.png';
import api from '../utils/api';

const huntLocation = 'flk2021';

const imgStyle = css`
  border-radius: 5px;
  background-size: cover;
  height: 100vh;
`;

const containerStyle = css`
  margin-top: 56px;
  position: relative;
`;

const boxStyle = css`
  overflow: visible;
  margin-left: auto;
  margin-right: auto;
`;

const clueImageStyle = css`
  width: 190px;
  margin: 5px;
`;

const contentStyle = css`
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
`;

const mapStyle = css`
  z-index: 1;
`;

const mapButtonStyle = css`
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 7;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const buttonImage = css`
  background-color: transparent;
  border: 1px solid;
  border-radius: 5px;
  margin: 5px;
  outline: none;
  text-align: left;
`;

const gooseberryStyle = css`
  position: absolute !important;
  z-index: 5;
  transform: translate(-50%, -50%);
`;

const popupStyle = css`
  display: inline-block;
  position: absolute;
  z-index: 20;
  width: 98vw;
`;

const noTopPadding = css`
  padding-top: 0px;
`;

const allPlaces = [
  {
    treasureid: "box1",
    top: 447,
    left: 639,
    grid: "H11",
    clue: "zip - trees - games",
    alt1: "Picture of trees with a path behind",
    alt2: "Picture of green gym exercise bike",
  },
  {
    treasureid: "box2",
    top: 488,
    left: 545,
    grid: "H9",
    clue: "sandy - pines - war",
    alt1: "Picture of pine trees and gravestones through a chain link fence",
    alt2: "Picture of path downhill and yellow triangle gate",
  },
  {
    treasureid: "box3",
    top: 458,
    left: 716,
    grid: "H12",
    clue: "secret - mirror - napoleon",
    alt1: "Picture of small stone steps ",
    alt2: "Picture of an aperture with fake tree trunks either side and mirror top and bottom",
  },
  {
    treasureid: "box4",
    top: 446,
    left: 799,
    grid: "H13",
    clue: "mermaid - palm - steps",
    alt1: "Picture of FAB icecream poster",
    alt2: "Picture of painted blue half circle symbol with 3 dots above, on concrete",
  },
  {
    treasureid: "box5",
    top: 488,
    left: 633,
    grid: "H11",
    clue: "antique - wood - unique",
    alt1: "Picture of orange strip awning",
    alt2: "Picture of lion head cornicing",
  },
  {
    treasureid: "box6",
    top: 473,
    left: 675,
    grid: "H11",
    clue: "politics - wells - oval",
    alt1: "Picture of Sandgate town logo",
    alt2: "Picture of HG Wells on a sign",
  },
  {
    treasureid: "box7",
    top: 389,
    left: 520,
    grid: "G9",
    clue: "lych - reverence - plain",
    alt1: "Picture of lych gate and pathway beyond",
    alt2: "Picture of decorative stonework arch",
  },
  {
    treasureid: "box8",
    top: 363,
    left: 676,
    grid: "F11",
    clue: "art - community - carnival",
    alt1: "Picture of triangle roof and window",
    alt2: "Picture of bronze sculpture ",
  },
  {
    treasureid: "box9",
    top: 351,
    left: 643,
    grid: "F11",
    clue: "mountain - bulk - food",
    alt1: "Picture of blue and white panel",
    alt2: "Picture of black door with yellow handle and stickers",
  },
  {
    treasureid: "box10",
    top: 351,
    left: 614,
    grid: "F10",
    clue: "teen - sofa - read",
    alt1: "Picture of painted lion",
    alt2: "Picture of stone door lintel with \"public\" engraved on it",
  },
  {
    treasureid: "box11",
    top: 298,
    left: 680,
    grid: "E11",
    clue: "enclosure - swing - dogs",
    alt1: "Picture of top of swing set and trees",
    alt2: "Picture of green metal gate",
  },
  {
    treasureid: "box12",
    top: 326,
    left: 757,
    grid: "F13",
    clue: "public - active - vending - ",
    alt1: "Picture of letters \"e\" and \"h\" in green",
    alt2: "Picture of vending machine buttons",
  },
  {
    treasureid: "box13",
    top: 360,
    left: 785,
    grid: "F13",
    clue: "tudor - tea - paperback",
    alt1: "Picture of cup cake painting",
    alt2: "Picture of white wall with black grid pattern",
  },
  {
    treasureid: "box14",
    top: 381,
    left: 781,
    grid: "G13",
    clue: "encircle - ash - king",
    alt1: "Picture of white Sir Jeffrey Hudson sculpture",
    alt2: "Picture of drooping tree",
  },
  {
    treasureid: "box15",
    top: 366,
    left: 846,
    grid: "F14",
    clue: "knowledge - study - reference",
    alt1: "Picture of carved floral red stone lintel",
    alt2: "Picture of bookshelves and blue carpet",
  },
  {
    treasureid: "box16",
    top: 382,
    left: 846,
    grid: "G14",
    clue: "jars - reuse - zero",
    alt1: "Picture of jars of food on a trolley",
    alt2: "Picture of letters \"RE:\" on wood panelling",
  },
  {
    treasureid: "box17",
    top: 389,
    left: 855,
    grid: "G14",
    clue: "knit - pearl - thread",
    alt1: "Picture of red and orange balls of wool ",
    alt2: "Picture of knitting needles in jar",
  },
  {
    treasureid: "box18",
    top: 404,
    left: 803,
    grid: "G13",
    clue: "sandwich - relax - windows",
    alt1: "Picture of blackboard with waiter image in white",
    alt2: "Picture of yellow sofa through window",
  },
  {
    treasureid: "box19",
    top: 405,
    left: 828,
    grid: "G14",
    clue: "gigs - gallery - market",
    alt1: "Picture of small tiles with letters HB in the centre",
    alt2: "Picture of curved gold door handles",
  },
  {
    treasureid: "box20",
    top: 361,
    left: 864,
    grid: "F14",
    clue: "embroider - copy - lobby",
    alt1: "Picture of brick with two windows",
    alt2: "Picture of colourful t-shirts hanging up",
  },
  {
    treasureid: "box21",
    top: 415,
    left: 855,
    grid: "G14",
    clue: "funicular - waiting - coffee",
    alt1: "Picture of sign with funicular",
    alt2: "Picture of tiles printed with B&amp;W images",
  },
  {
    treasureid: "box22",
    top: 389,
    left: 902,
    grid: "G15",
    clue: "refresh - table - waves",
    alt1: "Picture of chalkboard in shape of ice-cream",
    alt2: "Picture of letter F in bold black and orange on blue background",
  },
  {
    treasureid: "box23",
    top: 393,
    left: 883,
    grid: "G15",
    clue: "bingo - package - desk",
    alt1: "Picture of letters B I in blue and red and gold carpet",
    alt2: "Picture of white wall with 8 black edged windows",
  },
  {
    treasureid: "box24",
    top: 382,
    left: 865,
    grid: "G14",
    clue: "animal - friend - treat",
    alt1: "Picture of white line drawing of dog",
    alt2: "Picture of green door lintel",
  },
  {
    treasureid: "box25",
    top: 366,
    left: 889,
    grid: "F15",
    clue: "blacksmith - arch - heritage",
    alt1: "Picture of old fisherman",
    alt2: "Picture of life ring on brick wall with \"Folkestone\" written on it",
  },
  {
    treasureid: "box26",
    top: 326,
    left: 949,
    grid: "F16",
    clue: "defend - tower - golf",
    alt1: "Picture of white Martello tower, grass and sky",
    alt2: "Picture of white wall with graffiti",
  },
  {
    treasureid: "box27",
    top: 246,
    left: 964,
    grid: "D16",
    clue: "chalk - butterflies - cattle",
    alt1: "Picture of white cliffs and grass in front",
    alt2: "Picture of kissing gate with sea beyond",
  },
  {
    treasureid: "box28",
    top: 267,
    left: 882,
    grid: "E15",
    clue: "oasis - handmade - growing",
    alt1: "Picture of pebble poppy",
    alt2: "Picture of knitted flowers on fence",
  },
  {
    treasureid: "box29",
    top: 258,
    left: 882,
    grid: "E15",
    clue: "acorns - baptist - youth",
    alt1: "Picture of letters \"C H\" on brick",
    alt2: "Picture of red doors with black handles",
  },
  {
    treasureid: "box30",
    top: 326,
    left: 882,
    grid: "F15",
    clue: "jungle - green - home",
    alt1: "Picture of sign showing letters \"O P\"",
    alt2: "Picture of pot plants",
  },
  {
    treasureid: "box31",
    top: 246,
    left: 840,
    grid: "D14",
    clue: "learning - headstrong - books",
    alt1: "Picture of metal blue fence in swirly shape",
    alt2: "Picture of coat of arms on brick wall",
  },
  {
    treasureid: "box32",
    top: 521,
    left: 303,
    grid: "I5",
    clue: "sculpture - ivy - woods",
    alt1: "Picture of sculpture of man with arms in air ",
    alt2: "Picture of sculpture of ladies head with red hair",
  },
  {
    treasureid: "box33",
    top: 482,
    left: 164,
    grid: "H3",
    clue: "badgers - lake - mud",
    alt1: "Picture of trees reflected in water",
    alt2: "Picture of solar panel in water with trees behind",
  },
  {
    treasureid: "box34",
    top: 551,
    left: 326,
    grid: "I6",
    clue: "stories - independent - toys",
    alt1: "Picture of shelves with children’s books",
    alt2: "picture of cardboard children’s book character",
  },
  {
    treasureid: "box35",
    top: 556,
    left: 295,
    grid: "I5",
    clue: "root - food - holistic",
    alt1: "Picture of sunflower painting",
    alt2: "Picture of entrance way with white and black small tiles",
  },
  {
    treasureid: "box36",
    top: 530,
    left: 479,
    grid: "I8",
    clue: "seat - crossroads - canal",
    alt1: "Picture of cartoon of napoleonic soldier ",
    alt2: "Picture of grass, sky and bench",
  },
  {
    treasureid: "box37",
    top: 115,
    left: 85,
    grid: "K2",
    clue: "hops - brewing - barrel",
    alt1: "Picture of green wooden panels with \"alehouse\" in white",
    alt2: "Picture of writing \"paws for a drink\"",
  },
  {
    treasureid: "box38",
    top: 107,
    left: 56,
    grid: "K1",
    clue: "view - peace - bench",
    alt1: "Picture of blue arch shaped doors",
    alt2: "Picture of view with sea in distance",
  },
  {
    treasureid: "box39",
    top: 327,
    left: 79,
    grid: "R2",
    clue: "looker - wildlife - reserve",
    alt1: "Picture of binoculars",
    alt2: "Picture of blue butterfly and words \"kent wildlife trust\"",
  },
  {
    treasureid: "box40",
    top: 380,
    left: 57,
    grid: "T1",
    clue: "bricabrac - pilgrim - volunteer",
    alt1: "Picture of blue symbol of a circle and two curved lines",
    alt2: "Picture of plastic Christmas tree",
  },
  {
    treasureid: "box41",
    top: 176,
    left: 146,
    grid: "M4",
    clue: "wall - redoubt - remade",
    alt1: "Picture of red flag and blue sky",
    alt2: "Picture of wood with big metal rivets",
  },
  {
    treasureid: "box42",
    top: 252,
    left: 122,
    grid: "P3",
    clue: "bucket - books - cafe",
    alt1: "Picture of red bucket and spade on shop front",
    alt2: "Picture of sign saying \"coffee\"",
  },
  {
    treasureid: "box43",
    top: 236,
    left: 571,
    grid: "D10",
    clue: "horse - chalk - europe",
    alt1: "Picture of empty single track road and big expanse of sky",
    alt2: "Picture of view over Eurotunnel",
  },
  {
    treasureid: "box44",
    top: 224,
    left: 715,
    grid: "D12",
    clue: "orchids - framed - castle",
    alt1: "Picture of a picture of a horned cow",
    alt2: "Picture of a pathway up a hill",
  },
  {
    treasureid: "box45",
    top: 322,
    left: 458,
    grid: "F8",
    clue: "trail - crafts - woods",
    alt1: "Picture of a small wooden gate and a yellow arrow",
    alt2: "Picture of an ammonite carved in wood",
  },
  {
    treasureid: "box46",
    top: 268,
    left: 445,
    grid: "E8",
    clue: "trackbed - railway - gate",
    alt1: "Picture of a large gate with signs on it, on a trackway",
    alt2: "Picture of a signpost for the Elham Valley Way",
  },
  {
    treasureid: "box47",
    top: 101,
    left: 715,
    grid: "B12",
    clue: "community - clubs - disco",
    alt1: "Picture of metal sign saying Hawkinge",
    alt2: "Picture of glass door with metal semi circle handle",
  },
  {
    treasureid: "box48",
    top: 37,
    left: 669,
    grid: "A11",
    clue: "military - bluebells - nightingales",
    alt1: "Picture of woodland path",
    alt2: "Picture of blue exclamation mark sign on wooden post",
  },
  {
    treasureid: "box49",
    top: 222,
    left: 259,
    grid: "D5",
    clue: "radio - concrete - paths",
    alt1: "Picture of radio mast",
    alt2: "Picture of wooden post with arrow waymarkers for walkers",
  },
  {
    treasureid: "box50",
    top: 40,
    left: 256,
    grid: "A5",
    clue: "green - yellow - home",
    alt1: "Picture of part of green sign saying \"Let\"",
    alt2: "Picture of part of green sign saying \"Laing\"",
  },
];

class Map extends React.Component {

  state = {
    clueVisible: false,
    instructVisible: 0,
    zoom: 600,
    grid: null,
    clue: null,
    alt1: null,
    alt2: null,
    top: 0,
    left: 0,
    filePrefix: '',
    placesFound: []
  };

  componentDidMount() {
    api.refresh()
    .then(res => {
      if (res.status >= 400) {
        throw new Error('The server is down, please try later');
      }
    return (res.json());
    })
    .then(returned => {
      if (returned.result.treasure) {
        const placesFound = returned.result.treasure.filter(obj => obj.type === "box");
        this.setState({placesFound});
      }

      this.zoomInOut(1);
      this.scrollTo();
    })
  };

  zoomFactor = 1.2;
  zoomMost = 7566;
  scrollTop = 0;
  scrollLeft = 0;

  setScroll = () => {
    this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.scrollLeft = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
  };

  scrollTo = () => {
    setTimeout( () => {
      window.scrollTo(this.scrollLeft, this.scrollTop);
    }, 0 );
  };

  zoomIn = (e) => {
    this.zoomInOut(this.zoomFactor, e);
  };

  zoomOut = (e) => {
    this.zoomInOut(1/this.zoomFactor, e);
  };

  zoomInOut = (factor, e) => {
    e && e.stopPropagation();
    this.hideAll();

    var zoom = this.state.zoom * factor;
    const width = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) / 2;
    const height = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) / 2;

    if( zoom > this.zoomMost ) zoom = this.zoomMost;
    if( zoom < width*2 ) zoom = width*2;
    this.setState({zoom});
    this.scrollTop = (this.scrollTop + height) * factor - height;
    this.scrollLeft = (this.scrollLeft + width) * factor - width;
    this.scrollTo();
  };

  hideAll = () => {
    this.setState({clueVisible: false})
    this.setState({instructVisible: 0})
    return true;
  };


  render() {
    var {data} = this.props;
    const roadMapImage = data.roadMap.childImageSharp;
    const gooseberryFound = data.gooseberryFound.childImageSharp;
    const gooseberryColor = data.gooseberryColor.childImageSharp;
    const foundPlaces = this.state.placesFound;
    const placesFoundFromApi = (allPlaces, foundPlaces) => allPlaces.reduce((places, place, i) => {
      if (foundPlaces.some((foundPlace) => foundPlace.treasureid === place.treasureid)) {
        return [ ...places, { ...place, found: true } ]
      }
      return [ ...places, place ]
    }, [])

    const finalBoxArray = placesFoundFromApi(allPlaces, foundPlaces)

    allPlaces.forEach( function( obj ) {
      obj.showClue = function(e) {
        showAnyClue(e, obj);
      }
    });

    const showAnyClue = (e, obj) => {
      e.stopPropagation()
      this.hideAll();
      this.setScroll();
      this.setState({grid: obj.grid})
      this.setState({clue: obj.clue})
      this.setState({alt1: obj.alt1})
      this.setState({alt2: obj.alt2})
      this.setState({top: obj.top})
      this.setState({left: obj.left})
      this.setState({filePrefix: `/beacons-clues/${huntLocation}-${obj.treasureid}`})
      this.setState({clueVisible: true})
    };

    const nextInstruct = (e) => {
      e.stopPropagation();
      this.hideAll();
      if (!this.state.instructVisible) {
        this.setScroll();
      }

      const instructVisible = (this.state.instructVisible + 1) % 2;
      this.setState({instructVisible})
    };

    return (
      <PageWrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Map - Beacons</title>
        </Helmet>

        <Box bg={colors.primary} className={boxStyle} width={this.state.zoom}>
          <div className={containerStyle} onClick={this.hideAll}>
            <Img className={mapStyle} alt="road map" fluid={roadMapImage.fluid} />
            { finalBoxArray.map(gooseberry => (
              <div onClick={gooseberry.showClue}>
              <Img
                key={gooseberry.id}
                className={gooseberryStyle}
                alt="gooseberry"
                fixed={gooseberry.found ? gooseberryFound.fixed : gooseberryColor.fixed}
                style={{
                  top: gooseberry.top * this.state.zoom / 1000,
                  left: gooseberry.left * this.state.zoom / 1000,
                }}
              />
              </div>
            ))}

            { (this.state.clueVisible || this.state.instructVisible != 0) &&
              <div
                className={popupStyle}
                style={{
                  top: this.scrollTop,
                  left: this.scrollLeft,
                }}
              >
                { this.state.clueVisible &&
                  <PaperBox
                    width={[1, '80%', '60%']}
                    ml="auto"
                    mr="auto"
                    mt="50px"
                    px={[3, 3, 5]}
                    py={[3, 3, 3]}
                    color={colors.secondary}
                    textAlign="center"
                  >
                    <div>
                      <p className={noTopPadding}>Grid Ref: {this.state.grid}</p>
                      <p>{this.state.clue}</p>
                    </div>
                    <div>
                      <img alt={this.state.alt1} src={this.state.filePrefix + 'a.jpg'} className={clueImageStyle}/>
                      <img alt={this.state.alt2} src={this.state.filePrefix + 'b.jpg'} className={clueImageStyle}/>
                    </div>
                  </PaperBox>
                }

                { this.state.instructVisible > 0 &&
                  <PaperBox
                    width={[1, '80%', '60%']}
                    ml="auto"
                    mr="auto"
                    mt="50px"
                    px={[3, 3, 5]}
                    py={[3, 3, 3]}
                    color={colors.secondary}
                    textAlign="left"
                    onClick={nextInstruct}
                  >
                    { this.state.instructVisible == 1 &&
                      <>
                        <h2>Instructions</h2>

                        <ul>
                          <li>
                            Click a gooseberry <Img fixed={gooseberryColor.fixed} alt="gooseberry" /> on the map to reveal a clue to the location of a song fragment.
                          </li>
                          <li>Go out and search. You're looking for a small metal box in a publicly accessible space - indoors or outdoors. Maybe a cafe, shop, park, or community space.</li>
                          <li>You might have to ask for help, don't be shy, this is a great way to make new friends!</li>
                          <li>Collect as many song fragments as you can and register them on the <a href="/find">FOUND</a> page.</li>
                          <li>The more you find, the more messages you will discover and the more chance a Sea Gooseberry may visit you.</li>
                          <li>IMPORTANT: You can keep a song fragment from each box, but leave the box where you found it so others can enjoy the adventure.</li>
                        </ul>

                        <h2>Be Safe, Be Sensible</h2>

                        <ul>
                          <li>Only search during daylight and tell someone where you are heading.</li>
                          <li>Dress for the weather.</li>
                          <li>Many locations are easy to get to, but some might be harder to reach. Be mindful of your personal limitations and access needs, and ask for help if you need it.</li>
                          <li>We recommend taking a detailed map in addition this one.</li>
                          <li>There may not be wifi or data everywhere - so you could download or screenshot the clues and map just in case.</li>
                          <li>Paper maps are also available - see the <a href="/help">HELP</a> page.</li>
                        </ul>
                      </>
                    }
                  </PaperBox>
                }
              </div>
            }
          </div>
        </Box>

        <PaperBox className={mapButtonStyle}>
          <button className={buttonImage} onClick={nextInstruct}>
            <img alt="compass icon" src={compass} width="16"/>
            instructions
          </button>
          <button className={buttonImage} onClick={this.zoomIn}>
            + zoom
          </button>
          <button className={buttonImage} onClick={this.zoomOut}>
            - zoom
          </button>
        </PaperBox>

      </PageWrapper>
    );
  };
}



export const query = graphql`
  query {
    gooseberryFound: file(relativePath: { eq: "gooseberry-found.png" }) {
      childImageSharp {
        fixed(width: 23) {
        ...GatsbyImageSharpFixed
        }
      }
    }
    gooseberryColor: file(relativePath: { eq: "gooseberry-color.png" }) {
      childImageSharp {
        fixed(width: 23) {
        ...GatsbyImageSharpFixed
        }
      }
    }
    roadMap: file(relativePath: { eq: "roads-map.png" }) {
      childImageSharp {
        fluid(maxWidth: 7566) {
        ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default Map;
